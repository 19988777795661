import React, { useContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@hpstellar/core/visId';
import { Warning } from '@hpstellar/vis-icons';
import capitalize from 'lodash/capitalize';

import SearchSuggestionsRail from './search-suggestions-rail';
import SearchSuggestionsContent from './search-suggestions-content';
import SearchAutocompleteContext from './search-autocomplete-context';
import { getViewAllLink } from '../util';
import { getSearchAutoClickGtmAttributes } from '../../metrics/metrics-helpers';

import './css/search-suggestions.less';

const SearchSuggestions = props => {
    let {
        Categories,
        CategoryHeading,
        Content,
        ContentHeading,
        ContentCount,
        Count,
        KeywordField,
        Popular,
        PopularHeading,
        Products,
        ProductHeading,
        ProductCount,
        previewKeyword,
        SearchWebsiteUrl,
        ViewAllButtonLabel,
        noMatchingSearchKey,
        prices,
        onLinkHover,
        searchKey,
        autocompleteInputFocused,
        closeModal,
        resetAutocompletePreviewData,
        autocompleteModalOpen,
        setAutocompleteModalOpen,
        closeModalAndInputBar,
        trackSearchAutoClick,
        redirectLocation,
        getAutocompleteLinkData,
        support,
        autocompleteWithBreadcrumb,
        resetAutocompleteState,
        singleProductUrl
    } = useContext(SearchAutocompleteContext);

    const device = useSelector(state => state && state.ui && state.ui.customBreakpoints && state.ui.customBreakpoints.unifiedSearch);
    let isDesktop = device === 'desktop';

    const autocompleteOpenOnMobile = searchKey && autocompleteModalOpen && !isDesktop;

    useEffect(() => {
        const className = 'autocompleteOpen';
        const func = autocompleteOpenOnMobile ? 'add' : 'remove';
        document.body.classList[func](className);

        return () => {
            document.body.classList.remove(className);
        };
    }, [autocompleteOpenOnMobile]);

    useEffect(() => {
        const className = 'autocompleteOpenNoResult';
        const func = noMatchingSearchKey ? 'add' : 'remove';
        document.body.classList[func](className);

        return () => {
            document.body.classList.remove(className);
        };
    }, [noMatchingSearchKey])

    let viewAllLink = getViewAllLink(searchKey, redirectLocation, singleProductUrl);
    let viewAllLabel = singleProductUrl ? 'View Result' : 'See all results';

    let viewButton = (
        <Button
            to={viewAllLink}
            className="search-view-button"
            onMouseOver={resetAutocompletePreviewData}
            onClick={closeModalAndInputBar}
            variation='primary'
            theme='accent'
            {...getSearchAutoClickGtmAttributes(viewAllLabel, searchKey, 'see-all')}
        >
            {viewAllLabel}
        </Button>
    );

    const showResultSection = isDesktop || !noMatchingSearchKey;

    const noLeftRail =
        (!Array.isArray(Categories) || Categories.length === 0) && (!Array.isArray(Popular) || Popular.length === 0);
    
    const noPopularSearches = !Array.isArray(Popular) || Popular.length === 0;
    return (
        searchKey &&
        autocompleteModalOpen && (
            <>
                <div className={`hawk-search-suggestion-container${noMatchingSearchKey ? ' search-suggestion-no-results' : ''}${autocompleteWithBreadcrumb ? ' ac-with-bc' : ''}`}>
                    {noMatchingSearchKey ? (
                        <div className="search-suggestion-message">
                            <div className="search-suggestion-message-content ac-content">
                                <Warning 
                                    theme='dark'
                                    size='m'
                                    className='searchAlertSign'
                                />
                                <Typography variant='body1' className='search-suggestion-message-text'>
                                        {

                                            `Hmmm, we could not find anything${typeof searchKey === 'string' && searchKey.trim().length > 0 ? ` for ${capitalize(searchKey)}.` : '.'} Try a different search term.`
                                        }
                                </Typography>
                            </div>
                        </div>
                    ) : null}
                    <div className='ac-content'>
                        <div
                            className={`search-suggestion-popular-searches${noLeftRail ? ' empty-search-suggestions' : ''}${noPopularSearches ? ' empty-popular-searches' : ''}`}
                        >
                            <SearchSuggestionsRail
                                Categories={Categories}
                                CategoryHeading={CategoryHeading}
                                Popular={Popular}
                                PopularHeading={PopularHeading}
                                viewButton={viewButton}
                                device={device}
                                previewKeyword={previewKeyword}
                                onLinkHover={onLinkHover}
                                searchKey={searchKey}
                                trackSearchAutoClick={trackSearchAutoClick}
                                closeModalAndInputBar={closeModalAndInputBar}
                            />
                        </div>
                        {
                            showResultSection && (
                                <div className="search-suggestion-results">
                                    <SearchSuggestionsContent
                                        prices={prices}
                                        Products={Products}
                                        ProductHeading={ProductHeading}
                                        ProductCount={ProductCount}
                                        Content={Content}
                                        ContentHeading={ContentHeading}
                                        ContentCount={ContentCount}
                                        support={support}
                                        searchKey={searchKey}
                                        trackSearchAutoClick={trackSearchAutoClick}
                                        closeModalAndInputBar={closeModalAndInputBar}
                                        getAutocompleteLinkData={getAutocompleteLinkData}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="search-autocomplete-background" onClick={resetAutocompleteState} />
            </>
        )
    );
};

export default SearchSuggestions;
